import React, { useState } from "react";
//helemt
import { Helmet } from "react-helmet-async";
// localization
import { useLocales } from "../../../locales";

import { Link as RouterLink, useNavigate } from "react-router-dom";

// @mui
import {
  Box,
  Typography,
  Grid,
  Alert,
  Divider,
  Button,
  InputBase,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import FormProvider from "../../../components/hook-form/FormProvider";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container } from "@mui/system";
import { useSettingsContext } from "../../../components/settings";
import { RHFSelect, RHFTextField } from "../../../components/hook-form";
import ColumnsAccordionOpp from "../../../sections/dashboard/lawsuit/accordion/ColumnsAccordionOpp";
import LawsuitSearchBar from "../../../sections/dashboard/lawsuit/LawsuitSearchBar";
import OpponentList from "../../../sections/dashboard/collection/table/opponent-list";

// -----------------------------------------------------------------------------

//* ------- Styles --------
const personalImgStyle = {
  width: "100%",
  height: "100%",
};

const welcomeBoxStyle = {
  //direction="column"
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
};

const containerImgStyle = {
  display: { xs: "none", md: "flex" },
  position: "relative",
};

const logoBoxStyle = {
  display: "flex",
  position: "absolute",
  top: 0,
  left: 0,
  paddingTop: "20px",
  paddingLeft: "20px",
};

const logoStyle = {
  width: "80px",
  height: "77px",
};

const mainBoxSx = {
  display: "flex",
  flexDirection: "column",
  width: { xs: "90%", md: "615px" },
  alignItems: "flex-start",
};

const welcomeTextStyle = {
  fontSize: "46px",
  fontWeight: 700,
  color: "#2F2B2C",
  paddingBottom: "10px",
};

const innerTextStyle = {
  fontSize: "24px",
  fontWeight: 400,
  color: "#757575",
  paddingBottom: "",
};

const bigInnerTextStyle = {
  fontSize: "24px",
  fontWeight: 500,
  color: "#757575",
  paddingBottom: "25px",
};
const lableStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#2F2B2C",
  lineHeight: "20px",
};
const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};
const loadingButtonStyle = {
  fontSize: "18px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  paddingLeft: "28px",
  paddingright: "28px",
  paddingTop: "16px",
  paddingBottom: "16px",

  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  //marginLeft: "auto",
  borderRadius: "5px",
  //marginRight: "auto",
  width: "294px",
};
const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
};
const lawsuitTextStyle = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#6A8490",
};
const dividerStyle = {
  mt: "20px",
};
const topSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const topCancleButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2F2B2C",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "160px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  marginLeft: "20px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};
const flexBoxColumnStyle = {
  display: "flex",
  flexDirection: "column",
};
//___________________________________________________________
export default function SearchOppontent() {
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const [page, setPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState([]);

  const { translate } = useLocales();

  const FormScheema = Yup.object().shape({});

  const onSubmit = async (data) => {};
  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(FormScheema),
    defaultValues,
  });

  const {
    control,
    reset,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;
  // ------------- JSX Code ----------
  return (
    <>
      <Helmet>
        <title>{translate("صفحة بحث عن خصم ")}</title>
      </Helmet>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <Container sx={{}} maxWidth={themeStretch ? false : "xl"}>
          {/** Top Side */}
          <Box sx={bigFlexBoxStyle}>
            <Box sx={flexBoxStyle}>
              <img
                src="/assets/icons/arrow-left.svg"
                alt=""
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
                onClick={() => navigate(-1)}
              />
              <Typography sx={lawsuitTextStyle}> البحث عن خصم</Typography>
            </Box>
            <Box sx={flexBoxStyle}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="Contained"
                onClick={handleSubmit(onSubmit)}
                sx={topSaveButtonStyle}
              >
                بحث
              </LoadingButton>
              {/** */}
              <Button
                fullWidth
                onClick={() => navigate(-1)}
                size="large"
                type="submit"
                variant="Contained"
                sx={topCancleButtonStyle}
              >
                إفراغ الحقول
              </Button>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />

          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <Box sx={{ ...flexBoxColumnStyle, paddingTop: "10px" }}>
                <Typography sx={lableStyle}>الخصم</Typography>
                <Box style={{ display: "flex" }}>
                  <RHFSelect
                    name="sub_attribute_initial"
                    sx={{
                      ...txtFieldBorderStyle,
                      "& .MuiSelect-select span::before": {
                        content: "'اختر الخصم'",
                        color: "#697D95",
                        fontWeight: 300,
                        fontSize: "14px",
                      },
                    }}
                    SelectProps={{
                      native: false,
                      IconComponent: () => (
                        <img
                          src="/assets/icons/Icon.svg"
                          alt=""
                          style={{
                            marginRight: "19px",
                            marginLeft: "19px",
                          }}
                        />
                      ),
                    }}
                    onChange={(e) => {
                      setValue("sub_attribute_initial", e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  >
                    <InputBase
                      sx={{
                        padding: "10px",
                        fontSize: "14px",
                        fontWeight: 300,
                        color: "#697D95",
                      }}
                      placeholder="ابحث عن الصفة"
                    />
                    {[]?.map((attribute, index) => (
                      <MenuItem key={index} value={attribute.id}>
                        {attribute.name}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box sx={{ ...flexBoxColumnStyle, paddingTop: "10px" }}>
                <Typography sx={lableStyle}>رقم العقد</Typography>
                <Box style={{ display: "flex" }}>
                  <RHFTextField
                    name="matter_number_initial"
                    placeholder="ادخل رقم العقد"
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box sx={{ ...flexBoxColumnStyle, paddingTop: "10px" }}>
                <Typography sx={lableStyle}>رقم الملف</Typography>
                <RHFTextField
                  name="matter_number_initial"
                  placeholder="ادخل رقم الملف"
                  sx={{
                    ...txtFieldBorderStyle,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box sx={{ ...flexBoxColumnStyle, paddingTop: "10px" }}>
                <Typography sx={lableStyle}>الرقم المفصول</Typography>
                <RHFTextField
                  name="matter_number_initial"
                  placeholder="ادخل الرقم المفصول"
                  sx={{
                    ...txtFieldBorderStyle,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box sx={{ ...flexBoxColumnStyle, paddingTop: "10px" }}>
                <Typography sx={lableStyle}>رقم الهاتف</Typography>
                <RHFTextField
                  name="matter_number_initial"
                  placeholder="9xxxxxxx"
                  sx={{
                    ...txtFieldBorderStyle,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box sx={{ ...flexBoxColumnStyle, paddingTop: "10px" }}>
                <Typography sx={lableStyle}>الرقم المدني</Typography>
                <RHFTextField
                  name="matter_number_initial"
                  placeholder="ادخل الرقم المدني"
                  sx={{
                    ...txtFieldBorderStyle,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider sx={dividerStyle} />

          <Box
            sx={{
              ...bigFlexBoxStyle,
              mt: "30px",
              mb: "10px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box
              sx={{
                ...flexBoxStyle,
                position: "relative",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              {/** columns */}
              <ColumnsAccordionOpp />
            </Box>

            <LawsuitSearchBar
              placeholder={"البحث عن خصم"}
              // handleSearch={handleSearch}
              // searchValue={searchValue}
            />
          </Box>
          <OpponentList
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            setPage={setPage}
            page={page}
          />
          {/** */}
        </Container>
      </FormProvider>
    </>
  );
}
