import { useState, memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { InputBase } from "@mui/material";
// components
import { IconButtonAnimate } from "../../../components/animate";
import { useLocales } from "../../../locales";

// ----------------------------------------------------------------------

const StyledSearchbar = styled("div")(({ theme }) => ({
  height: "40px",
  width: "233px",
  paddingLeft: "10px",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    width: "233px",
    // left: "50%",
    // top: "50%",
    // transform: "translate(-50%, -50%)",
  },
  [theme.breakpoints.down("md")]: {
    // width: "60%",
    /* right: 0,
    "&:focus": {
      width: "90%",
    },*/
  },
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: "10px",
  border: "1px solid #D8D8D8",
  borderRadius: "8px",
  borderCollapse: "collapse",
  [theme.breakpoints.up("sm")]: {
    marginLeft: "10px",
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  width: "16px",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "37px",
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "black",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

// ----------------------------------------------------------------------

function Searchbar() {
  const { translate } = useLocales();

  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <StyledSearchbar>
        <Search>
          <SearchIconWrapper>
            <IconButtonAnimate>
              <img
                height="16px"
                width="16px"
                alt=""
                src="/assets/icons/navbar/search_ic.svg"
              />
            </IconButtonAnimate>
          </SearchIconWrapper>
          <StyledInputBase placeholder={translate("search")} />
        </Search>
      </StyledSearchbar>
    </>
  );
}

export default memo(Searchbar);
