// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import { ThemeSettings } from "./components/settings";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getClientByPhoneService } from "./redux/services/general_service";

function App() {
  const dispatch = useDispatch();

  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("Token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const checkTelUrl = async (url) => {
    const telRegex = /tel:(.*)$/;
    const isHave = url.includes("tel");

    // return match && match[1];
    if (isHave) {
      const match = url?.match(telRegex);
      await dispatch(getClientByPhoneService({ phone_number: match[1] }))
    }
  };

  useEffect(() => {
    checkTelUrl(window.location.pathname);
  }, []);
  return (
    <ThemeProvider>
      <ThemeSettings>
        <ThemeLocalization>
          <Router />
        </ThemeLocalization>
      </ThemeSettings>
    </ThemeProvider>
  );
}

export default App;
