import { createSlice } from "@reduxjs/toolkit";
import {
  getAttachmentTypeListService,
  getAttachmentListService,
  uploadAttachmentServices,
  deleteAttachmentServices,
  filterAttachmentsServices,
  updateAttachmentsServices,
} from "../services/attachment_service";
import { AttachmentsSessionColumnsList } from "../../utils/columnsLists";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingDelete: false,
  attachmentType: [],
  isLoadingAttachmentList: false,
  attachmentList: [],
  AttachmentsSessionColumnsList: AttachmentsSessionColumnsList,
  isLoadingFilter: false,
  isLoadingUpdate: false,
};

const slice = createSlice({
  name: "attachment",
  initialState,
  reducers: {
    setAttachmentsSessionColumnsList: (state, action) => {
      state.AttachmentsSessionColumnsList = action.payload;
    },
  },
  extraReducers: {
    // GET =>  All ATTACHMENT TYPE
    [getAttachmentTypeListService.fulfilled]: (state, action) => {
      state.isLoadingAttachmentList = false;
      state.attachmentType = action.payload?.data?.data;
    },
    [getAttachmentTypeListService.rejected]: (state, action) => {
      state.isLoadingAttachmentList = false;
    },
    [getAttachmentTypeListService.pending]: (state) => {
      state.isLoadingAttachmentList = true;
    },

    // GET =>  All ATTACHMENT Service
    [getAttachmentListService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.attachmentList = action.payload?.data?.data;
    },
    [getAttachmentListService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAttachmentListService.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  UPLOAD ATTACHMENT
    [uploadAttachmentServices.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [uploadAttachmentServices.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [uploadAttachmentServices.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  UPLOAD ATTACHMENT
    [deleteAttachmentServices.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteAttachmentServices.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteAttachmentServices.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // POST =>  FILTER ATTACHMENT
    [filterAttachmentsServices.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
      state.attachmentList = action.payload?.data;
    },
    [filterAttachmentsServices.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterAttachmentsServices.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },
    // PUT =>  FILTER ATTACHMENT
    [updateAttachmentsServices.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateAttachmentsServices.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateAttachmentsServices.pending]: (state) => {
      state.isLoadingUpdate = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setAttachmentsSessionColumnsList } = slice.actions;
