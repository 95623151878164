import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  All COURTS SESSION
export const getCourtsSessionService = createAsyncThunk(
  "courts/getCourtsSession",
  async ({ id, page, session_id }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.courtsSessionURL}/${id}/?page=${page}&session_id=${session_id}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>   COURT SESSION
export const getCourtSessionService = createAsyncThunk(
  "courts/getCourtSessionService",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.court_sessions}/${id}/show`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE  SESSION
export const createCourtsSessionServices = createAsyncThunk(
  "courts/createCourtsSession",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  UPDATE DELEGTION NOTE
export const updateDelegtionNoteServices = createAsyncThunk(
  "courts/updateDelegtionNote",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.updateDelegtionNoteURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER COURTS SERVICE
export const filterCourtsService = createAsyncThunk(
  "courts/filterCourtsService",
  async ({ data, page }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.filterCourtsSessionURL}?page=${page || 1}`,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER Experts SERVICE
export const filterExpertsService = createAsyncThunk(
  "courts/filterExpertsService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER Administrative SERVICE
export const filterAdministrativeService = createAsyncThunk(
  "courts/filterAdministrativeService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER Excute SERVICE
export const filterExcuteService = createAsyncThunk(
  "courts/filterExcuteService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET ALL PROSEDUERS LIST
export const getProseduersService = createAsyncThunk(
  "courts/getProseduersService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.list_procedures_with_subprocedures);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
