import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// POST =>  CREATE OFFICE
export const createOfficeervice = createAsyncThunk(
  "office/createOfficeervice",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createOfficeURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
