import { styled, alpha } from "@mui/material/styles";

import { InputBase } from "@mui/material";

import { IconButtonAnimate } from "../../../components/animate";

//______________________________________________________________

const StyledSearchbar = styled("div")(({ theme }) => ({
  height: "40px",
  width: { xs: "", sm: "200", md: "290px" },
  paddingLeft: "10px",
  position: "relative",

  [theme.breakpoints.up("md")]: {
    width: "250px",
  },
  [theme.breakpoints.down("md")]: {},
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FEFEFE",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: "10px",
  borderRadius: "5px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: "10px",
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  width: "15px",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "37px",
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "black",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

//________________________________________________________

const LawsuitSearchBar = ({ placeholder, handleSearch, searchValue }) => {
  // ---------- JSX Code ------------a

  return (
    <>
      <StyledSearchbar>
        <Search>
          {/* <Diversity1Outlined> */}
          <SearchIconWrapper>
            <IconButtonAnimate>
              <img
                height="16px"
                width="16px"
                alt="search"
                src="/assets/icons/search.svg"
              />
            </IconButtonAnimate>
          </SearchIconWrapper>
          {/* </Diversity1Outlined> */}

          <StyledInputBase
            value={searchValue}
            placeholder={placeholder}
            onChange={handleSearch}
          />
        </Search>
      </StyledSearchbar>
    </>
  );
};
export default LawsuitSearchBar;
