import PropTypes from "prop-types";
// @mui
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

// ----------------------------------------------------------------------

TableHeadCustom.propTypes = {
  sx: PropTypes.object,
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllRows: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel = [],
  numSelected = 0,
  onSort,
  onSelectAllRows,
  isCheckBox = true,
  sx,
}) {
  const isAllSelected = rowCount > 0 && numSelected === rowCount;

  return (
    <TableHead sx={sx}>
      <TableRow>
        {isCheckBox && (
          <TableCell
            padding="checkbox"
            sx={{
              color: "#6A8490",
              fontSize: "16px",
              fontWeight: 400,
              bgcolor: "#FEFEFE",
              lineHeight: "24px",
            }}
          ></TableCell>
        )}

        {headLabel?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              color: "#6A8490",
              fontSize: "16px",
              fontWeight: 400,
              bgcolor: "#FEFEFE",
              lineHeight: "24px",
            }}
          >
            {onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => onSort(headCell.id)}
                sx={{ textTransform: "capitalize" }}
              >
                {headCell.label}

                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
