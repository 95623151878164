import * as React from "react";

import { PieChart } from "@mui/x-charts/PieChart";
//_______________________________________________________________-
export default function CasesTypePieChart({
  completed_matter_count,
  not_completed_matter_count,
}) {
  const jobs = [
    { name: "مكتملة", value: completed_matter_count },
    { name: "غير مكتملة", value: not_completed_matter_count },
  ];

  const pieData = jobs?.map((job, index) => ({
    id: index,
    value: job.value,
    label: job.name,
  }));

  const colorPalette = ["#6a8490", "#babcdf"];
  return (
    <PieChart
      series={[
        {
          data: pieData,
        },
      ]}
      width={500}
      height={300}
      colors={colorPalette}
    />
  );
}
