import * as React from "react";

import { LineChart } from "@mui/x-charts/LineChart";

// Example data for Revenues and Exports for all months
const revenuesData = [
  0, 5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 9800, 10000,
];
const exportsData = [
  0, 3000, 3200, 3500, 3700, 4000, 4200, 4500, 4700, 5000, 5200, 5500, 5700,
];

// Months for x-axis labels
const months = [
  "",
  "يناير",
  "فبراير",
  "مارس",
  "أبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export default function RevenuesExports() {
  return (
    <LineChart
      width={1000}
      height={600}
      series={[
        { data: revenuesData, label: "الواردات", color: "#706e6f" },
        { data: exportsData, label: "الصادرات", color: "#6a8490" },
      ]}
      xAxis={[{ scaleType: "point", data: months }]}
    />
  );
}
