import { createSlice } from "@reduxjs/toolkit";
import {
  getQuickRegistration,
  updateAssignedEmployee,
  updateCourtSessionDate,
  updateProcedureStatement,
  getTemplatesListService,
  getTemplateFieldsService,
  createTemplateService,
  createReportService,
  getTemplatesClientListService,
  getTemplateClientFieldsService,
  createClientTemplateService,
  createClientReportService,
  getTemplatesCourtSessionsListService,
  getTemplateCourtSessionsFieldService,
  createCourtSessionTemplateService,
  createCourtReport,
  createSessionReportService,
} from "../services/reports_service";
import { reportsColumnsList } from "../../utils/columnsLists";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingQuickRegistration: false,
  isLoadingUpdateSessionDate: false,
  isLoadingUpdateProcedureStatement: false,
  isLoadingUpdateAssignedEmployee: false,
  QuickRegistrationList: [],
  reportsColumnsList: reportsColumnsList,
  templateList: [],
  templateField: [],

  templateClientList: [],
  templateClientField: [],
  templateCourtSessionsList: [],
  templateCourtSessionField: [],

  isLoadingCreate: false,
  isLoading: false,
};

const slice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReportsColumnsList: (state, action) => {
      state.reportsColumnsList = action.payload;
    },
  },
  extraReducers: {
    // ==> GET QuickRegistration
    [getQuickRegistration.fulfilled]: (state, action) => {
      state.isLoadingQuickRegistration = false;
      state.QuickRegistrationList = action.payload?.data?.data;
    },
    [getQuickRegistration.rejected]: (state, action) => {
      state.isLoadingQuickRegistration = false;
    },
    [getQuickRegistration.pending]: (state) => {
      state.isLoadingQuickRegistration = true;
    },

    // ==> PUT  court session date
    [updateCourtSessionDate.fulfilled]: (state, action) => {
      state.isLoadingUpdateSessionDate = false;
    },
    [updateCourtSessionDate.rejected]: (state, action) => {
      state.isLoadingUpdateSessionDate = false;
    },
    [updateCourtSessionDate.pending]: (state) => {
      state.isLoadingUpdateSessionDate = true;
    },

    // ==> PUT   procedure statement
    [updateProcedureStatement.fulfilled]: (state, action) => {
      state.isLoadingUpdateProcedureStatement = false;
    },
    [updateProcedureStatement.rejected]: (state, action) => {
      state.isLoadingUpdateProcedureStatement = false;
    },
    [updateProcedureStatement.pending]: (state) => {
      state.isLoadingUpdateProcedureStatement = true;
    },

    // ==> PUT   assigned employee
    [updateAssignedEmployee.fulfilled]: (state, action) => {
      state.isLoadingUpdateAssignedEmployee = false;
    },
    [updateAssignedEmployee.rejected]: (state, action) => {
      state.isLoadingUpdateAssignedEmployee = false;
    },
    [updateAssignedEmployee.pending]: (state) => {
      state.isLoadingUpdateAssignedEmployee = true;
    },

    // ==> GET   GET Templates List
    [getTemplatesListService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.templateList = action.payload.data;
    },
    [getTemplatesListService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTemplatesListService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET   GET Templates List
    [getTemplatesClientListService.fulfilled]: (state, action) => {
    

      state.isLoading = false;
      state.templateClientList = action.payload.data?.data;
    },
    [getTemplatesClientListService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTemplatesClientListService.pending]: (state) => {
      state.isLoading = true;
    },
    // GET =>  GET Template Court sessions
    [getTemplatesCourtSessionsListService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.templateCourtSessionsList = action.payload.data?.data;
    },
    [getTemplatesCourtSessionsListService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTemplatesCourtSessionsListService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET   GET Templates Matter Field
    [getTemplateFieldsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.templateField = action.payload.data;
    },
    [getTemplateFieldsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTemplateFieldsService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET   GET Templates Client Field
    [getTemplateClientFieldsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.templateClientField = action.payload.data?.data;
    },
    [getTemplateClientFieldsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTemplateClientFieldsService.pending]: (state) => {
      state.isLoading = true;
    },
    // ==> GET   GET Templates Client Field
    [getTemplateCourtSessionsFieldService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.templateCourtSessionField = action.payload.data?.data;
    },
    [getTemplateCourtSessionsFieldService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTemplateCourtSessionsFieldService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> Create Template For Matter
    [createTemplateService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createTemplateService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createTemplateService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> Create Report For Matter
    [createReportService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createReportService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createReportService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // ==> Create Report For session
    [createSessionReportService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createSessionReportService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createSessionReportService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // ==> Create TEMPLATE For Court Session
    [createCourtSessionTemplateService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createCourtSessionTemplateService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createCourtSessionTemplateService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // ==> Create REPORT For Court
    [createCourtReport.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createCourtReport.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createCourtReport.pending]: (state) => {
      state.isLoadingCreate = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setInvoceId, setReportsColumnsList } = slice.actions;
