import { createSlice } from "@reduxjs/toolkit";
import {
  getInvoicesService,
  createPaymentService,
  createStatementsService,
  createPaymentReceiptService,
  getMattersByClient,
  createFinanceFilterService,
  exportStatementsService,
  getImportExportsService,
  getPaymentReceiptsListService,
  getGeneralExpensesService,
  createGeneralExpensesService,
  sendEmailToClientService,
} from "../services/finance_service";

import {
  invoicesColumnsList,
  voucherColumnsList,
  AccountsColumnsList,
  genralExpenseColumnsList,
} from "../../utils/columnsLists";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingInvoices: false,
  isLoadingCreate: false,
  isLoading: false,
  invoicesList: [],
  //
  isLoadingExpenses: false,
  ExpensesList: [],
  //
  isLoadingCreateInvoice: false,
  //
  isLoadingCreateExpense: false,
  invoice: null,
  invoicesColumnsList: invoicesColumnsList,
  voucherColumnsList: voucherColumnsList,
  isLoadinggetMatters: false,
  mattersByClient: [],
  AccountsColumnsList: AccountsColumnsList,
  count: null,
  total_cost: null,
  dataToExport: {},
  generalExpenseColumnsList: genralExpenseColumnsList,
  paymentRecipitsList: [],
  generalExpenseList: [],
  total: null,
  expensesCount: null,
  invoiceId: null,
  isLoadingClientEmail: false,
  invoicesCount:null
};

const slice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    setInvoceId: (state, action) => {
      state.invoice = action.payload;
    },
    setInvoicesColumnsList: (state, action) => {
      state.invoicesColumnsList = action.payload;
    },
    setVoucherColumnsList: (state, action) => {
      state.voucherColumnsList = action.payload;
    },
    setResetMatterList: (state, action) => {
      state.mattersByClient = [];
    },

    setAccountColumnsList: (state, action) => {
      state.AccountsColumnsList = action.payload;
    },
    setDataToExport: (state, action) => {
      state.dataToExport = action.payload;
    },

    setGeneralExpenseColumnsList: (state, action) => {
      state.generalExpenseColumnsList = action.payload;
    },

    setVoucherId: (state, action) => {
      state.invoiceId = action.payload;
    },
  },
  extraReducers: {
    // ==> GET INVOICES
    [getInvoicesService.fulfilled]: (state, action) => {
      state.isLoadingInvoices = false;
      state.invoicesList = action.payload.data.data;
      state.invoicesCount = action.payload.data.count;
    },
    [getInvoicesService.rejected]: (state, action) => {
      state.isLoadingInvoices = false;
    },
    [getInvoicesService.pending]: (state) => {
      state.isLoadingInvoices = true;
    },

    // ==> CREATE PAYMENT
    [createPaymentService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> CREATE STATEMENT
    [createStatementsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // POST =>  CREATE PAYMENT RECEPIPT
    [createPaymentReceiptService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentReceiptService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentReceiptService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    [getMattersByClient.fulfilled]: (state, action) => {
      state.isLoadinggetMatters = false;
      state.mattersByClient = action.payload;
    },
    [getMattersByClient.rejected]: (state, action) => {
      state.isLoadinggetMatters = false;
    },
    [getMattersByClient.pending]: (state) => {
      state.isLoadinggetMatters = true;
    },

    [createFinanceFilterService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoicesList = action.payload;
    },
    [createFinanceFilterService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [createFinanceFilterService.pending]: (state) => {
      state.isLoading = true;
    },

    [exportStatementsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;

      state.expenses = action.payload.data?.expenses;
      state.count = action.payload?.count;
      state.total_cost = action.payload?.data?.total_cost;
    },
    [exportStatementsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [exportStatementsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    [getImportExportsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [getImportExportsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [getImportExportsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //getPaymentReceiptsListService

    [getPaymentReceiptsListService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.paymentRecipitsList = action.payload.data?.data;
      state.count = action.payload.data.count;
    },
    [getPaymentReceiptsListService.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.errors[0] || "حدث خطأ");
    },
    [getPaymentReceiptsListService.pending]: (state) => {
      state.isLoading = true;
    },

    //getPaymentReceiptsListService

    [getGeneralExpensesService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.generalExpenseList = action.payload.data?.data?.general_expenses;
      state.total = action.payload.data?.data?.total;
      state.expensesCount = action.payload.data?.count;
    },
    [getGeneralExpensesService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getGeneralExpensesService.pending]: (state) => {
      state.isLoading = true;
    },

    //createGeneralExpensesService

    [createGeneralExpensesService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createGeneralExpensesService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
      toast.error(action.payload.errors[0] || "حدث خطأ");
    },
    [createGeneralExpensesService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> send email to client
    [sendEmailToClientService.fulfilled]: (state, action) => {
      state.isLoadingClientEmail = false;
    },
    [sendEmailToClientService.rejected]: (state, action) => {
      state.isLoadingClientEmail = false;
    },
    [sendEmailToClientService.pending]: (state) => {
      state.isLoadingClientEmail = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setInvoceId,
  setInvoicesColumnsList,
  setVoucherColumnsList,
  setResetMatterList,
  setAccountColumnsList,
  setDataToExport,
  setGeneralExpenseColumnsList,
  setVoucherId,
} = slice.actions;
