import { createSlice } from "@reduxjs/toolkit";

import { getStatisticService } from "../services/statistics_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingStatistic: false,
  Statistic: [],
};

const slice = createSlice({
  name: "statistic",
  initialState,
  reducers: {},
  extraReducers: {
    // ==> GET STATISTIC
    [getStatisticService.fulfilled]: (state, action) => {
      state.isLoadingStatistic = false;
      state.Statistic = action.payload.data;
    },
    [getStatisticService.rejected]: (state, action) => {
      state.isLoadingStatistic = false;
    },
    [getStatisticService.pending]: (state) => {
      state.isLoadingStatistic = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setUsersColumnsList, setIsLoading } = slice.actions;
