import { createSlice } from "@reduxjs/toolkit";
import {
  getMatterStatusService,
  getMatterTypeService,
  getSessionService,
  createMatterTypeService,
  createMatterStatusService,
  craeteSessionService,
  createCourtsService,
  getCourtsService,
  createGovernmentService,
  getGovernmentService,
  createAttributesService,
  createSubAttributesService,
  getSubAttributesService,
  createProceduresService,
  createSubProceduresService,
  getSubProcWithSubAttService,
  createExpenseService,
  getExpenseService,
  createMatterService,
  getMatterService,
  getInvoiceService,
  createInvoiceService,
  createExpenseMatterService,
  getExpensesMatterService,
  getInvoicesForMatterService,
  getMatterByIdService,
  createContractService,
  createNewInvoiceService,
  createFilterService,
  //
  getContractsService,
  addHoursService,
  getHoursService,
  editMatterService,
} from "../services/matter_service";
import {
  CourtSessionColumnsList,
  lawColumnList,
} from "../../utils/columnsLists";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingCreateMatter: false,
  isLoadingCreate: false,
  isLoading: false,
  isLoadingMatters: false,
  isLoadingSubPro: false,

  matterTypes: [],
  matterStatus: [],
  matterSession: [],
  expense: [],
  governments: [],
  courts: [],
  attributes: [],
  matters: [],
  subPro: [],
  matterId: null,
  expensesList: [],
  invoicesMatterList: [],
  matterInfo: {},
  advance_payment_type: "unit",
  later_payment_type: "unit",
  advance_payment_schedule_type: "fixed",
  later_payment_schedule_type: "fixed",
  collected: false,
  isLoadingCreateNewInvoice: false,
  expencesChecked: [],
  hoursInfo: [],
  columnsList: lawColumnList,
  clientDependency: {},
  matterContrats: null,
  isLoadingContrats: false,
  clients: [],
  teams: [],
  matterType: [],
  matterGrade: [],
  sessionId: null,
  session_type: null,
  session_status: null,
  isFiltring: false,
  isLoadingMatt: false,
  isLoadingMatterType: false,
  dateToFilter: {},
};

const slice = createSlice({
  name: "matter",
  initialState,
  reducers: {
    setMatterId: (state, action) => {
      state.matterId = action.payload;
    },
    setAdvancePaymentType: (state, action) => {
      state.advance_payment_type = action.payload;
    },
    setLaterPaymentType: (state, action) => {
      state.later_payment_type = action.payload;
    },
    setAdvancePaymentScheduleType: (state, action) => {
      state.advance_payment_schedule_type = action.payload;
    },
    setLaterPaymentScheduleType: (state, action) => {
      state.later_payment_schedule_type = action.payload;
    },
    setIsCollected: (state, action) => {
      state.collected = action.payload;
    },
    //
    setExpensesChecked: (state, action) => {
      state.expencesChecked = action.payload;
    },
    setColumnsList: (state, action) => {
      state.columnsList = action.payload;
    },

    setClientDependency: (state, action) => {
      state.clientDependency = action.payload;
    },
    setContractNull: (state, action) => {
      state.matterContrats = null;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setTeams: (state, action) => {
      state.teams = action.payload;
    },
    setMatterGrade: (state, action) => {
      state.matterGrade = action.payload;
    },
    setMatterType: (state, action) => {
      state.matterType = action.payload;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setSessionType: (state, action) => {
      state.session_type = action.payload;
    },
    setSessionStatus: (state, action) => {
      state.session_status = action.payload;
    },
    setIsFiltring: (state, action) => {
      state.isFiltring = action.payload.isFiltring;
      state.dateToFilter = action.payload.dateToFilter;
    },
  },
  extraReducers: {
    // POST =>  CREATE MATTER
    [createMatterTypeService.fulfilled]: (state, action) => {
      state.isLoadingCreateMatter = false;
    },
    [createMatterTypeService.rejected]: (state, action) => {
      state.isLoadingCreateMatter = false;
    },
    [createMatterTypeService.pending]: (state) => {
      state.isLoadingCreateMatter = true;
    },

    // EDIT =>  EDIT MATTER
    [editMatterService.fulfilled]: (state, action) => {
      state.isLoadingCreateMatter = false;
    },
    [editMatterService.rejected]: (state, action) => {
      state.isLoadingCreateMatter = false;
    },
    [editMatterService.pending]: (state) => {
      state.isLoadingCreateMatter = true;
    },
    // GET =>  GET MATTER SERVICE
    [getMatterTypeService.fulfilled]: (state, action) => {
      state.isLoadingMatterType = false;
      state.matterTypes = action.payload.data;
    },
    [getMatterTypeService.rejected]: (state, action) => {
      state.isLoadingMatterType = false;
    },
    [getMatterTypeService.pending]: (state) => {
      state.isLoadingMatterType = true;
    },

    // GET =>  GET MATTER STATUSE
    [getMatterStatusService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.matterStatus = action.payload.data;
    },
    [getMatterStatusService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getMatterStatusService.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  CREATE MATTER STATUSE
    [createMatterStatusService.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createMatterStatusService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [createMatterStatusService.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  CREATE MATTER SESSION
    [craeteSessionService.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [craeteSessionService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [craeteSessionService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>  GET SESSION
    [getSessionService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.matterSession = action.payload?.data?.data;
    },
    [getSessionService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSessionService.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  CREATE COURTS
    [createCourtsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createCourtsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createCourtsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // GET =>  GET COURTS
    [getCourtsService.pending]: (state) => {
      state.isLoading = true;
      state.isLoadingCourts = true;
    },  
    [getCourtsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isLoadingCourts = false;
      state.courts = action.payload.data;
    },
    [getCourtsService.rejected]: (state, action) => {
      state.isLoadingCourts = false;
      state.isLoading = false;
    },

    // GET =>  GET SUB ATTRIBUTE

    [getSubAttributesService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.attributes = action.payload.data;
    },
    [getSubAttributesService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSubAttributesService.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  CREATE SUB ATTRIBUTES

    [createSubAttributesService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createSubAttributesService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createSubAttributesService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // GET =>  GET EXPENSE
    [getGovernmentService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.governments = action.payload.data;
    },
    [getGovernmentService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getGovernmentService.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  CREATE EXPENSE
    [createExpenseService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createExpenseService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createExpenseService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // GET =>  GET EXPENSE
    [getExpenseService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.expense = action.payload.data;
    },
    [getExpenseService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getExpenseService.pending]: (state) => {
      state.isLoading = true;
    },
    // POST =>  CREATE MATTER
    [createMatterService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createMatterService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createMatterService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // Get =>  GET MATTER
    [getMatterService.fulfilled]: (state, action) => {
      state.isLoadingMatters = false;
      state.matters = action?.payload?.data?.data;
      state.matterCount = action?.payload?.data?.count;
    },
    [getMatterService.rejected]: (state, action) => {
      state.isLoadingMatters = false;
    },
    [getMatterService.pending]: (state) => {
      state.isLoadingMatters = true;
    },

    //

    [getSubProcWithSubAttService.fulfilled]: (state, action) => {
      state.isLoadingSubPro = false;
      state.subPro = action.payload.data;
    },
    [getSubProcWithSubAttService.rejected]: (state, action) => {
      state.isLoadingSubPro = false;
    },
    [getSubProcWithSubAttService.pending]: (state) => {
      state.isLoadingSubPro = true;
    },

    // ==> GET INVOICES
    [getInvoiceService.fulfilled]: (state, action) => {
      state.isLoadingInvoices = false;
      state.invoicesList = action.payload.data;
    },
    [getInvoiceService.rejected]: (state, action) => {
      state.isLoadingInvoices = false;
    },
    [getInvoiceService.pending]: (state) => {
      state.isLoadingInvoices = true;
    },

    // ==> GET EXPENSES
    [getExpensesMatterService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.expensesList = action.payload.data;
    },
    [getExpensesMatterService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getExpensesMatterService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> POST CREATE INVOICE
    [createInvoiceService.fulfilled]: (state, action) => {
      state.isLoadingCreateInvoice = false;
    },
    [createInvoiceService.rejected]: (state, action) => {
      state.isLoadingCreateInvoice = false;
    },
    [createInvoiceService.pending]: (state) => {
      state.isLoadingCreateInvoice = true;
    },

    // ==> POST CREATE Expense
    [createExpenseMatterService.fulfilled]: (state, action) => {
      state.isLoadingCreateExpense = false;
    },
    [createExpenseMatterService.rejected]: (state, action) => {
      state.isLoadingCreateExpense = false;
    },
    [createExpenseMatterService.pending]: (state) => {
      state.isLoadingCreateExpense = true;
    },

    // GET =>  GET INVOICES FOR MATTER
    [getInvoicesForMatterService.fulfilled]: (state, action) => {
      state.isLoadingMatt = false;
      state.invoicesMatterList = action.payload.data.results;
    },
    [getInvoicesForMatterService.rejected]: (state, action) => {
      state.isLoadingMatt = false;
    },
    [getInvoicesForMatterService.pending]: (state) => {
      state.isLoadingMatt = true;
    },

    // GET =>  GET  MATTER BY ID
    [getMatterByIdService.pending]: (state) => {
      state.isLoading = true;
    },
    [getMatterByIdService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.matterInfo = action?.payload?.data;
    },
    [getMatterByIdService.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // GET =>  GET  MATTER BY ID
    [createContractService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createContractService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createContractService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // GET =>
    [createNewInvoiceService.fulfilled]: (state, action) => {
      state.isLoadingCreateNewInvoice = false;
    },
    [createNewInvoiceService.rejected]: (state, action) => {
      state.isLoadingCreateNewInvoice = false;
    },
    [createNewInvoiceService.pending]: (state) => {
      state.isLoadingCreateNewInvoice = true;
    },

    // GET =>
    [createFilterService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
      state.isLoading = false;
      state.isLoadingMatters = false;

      state.matters = action?.payload?.data?.data;
      state.matterCount = action?.payload?.data?.count;
    },
    [createFilterService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
      state.isLoading = false;
      state.isLoadingMatters = false;
    },
    [createFilterService.pending]: (state) => {
      state.isLoadingCreate = true;
      state.isLoading = true;
      state.isLoadingMatters = true;
    },

    // GET =>  GET MATTER CONTRACTS
    [getContractsService.fulfilled]: (state, action) => {
      state.isLoadingContrats = false;

      if (action?.payload?.data?.message !== undefined) {
        state.matterContrats = null;
      } else {
        state.matterContrats = action?.payload?.data;
      }
    },
    [getContractsService.rejected]: (state, action) => {
      state.isLoadingContrats = false;
      state.matterContrats = null;
    },
    [getContractsService.pending]: (state) => {
      state.isLoadingContrats = true;
    },

    // GET =>  GET  MATTER BY ID
    [addHoursService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [addHoursService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [addHoursService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // GET =>  GET  MATTER BY ID
    [getHoursService.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.isLoading = false;
      state.hoursInfo = action?.payload?.data?.data;
    },
    [getHoursService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getHoursService.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setMatterId,
  setAdvancePaymentType,
  setAdvancePaymentScheduleType,
  setIsCollected,
  setExpensesChecked,
  setColumnsList,
  setCourtSessionColumnsList,
  setClientDependency,
  setContractNull,
  setLaterPaymentType,
  setLaterPaymentScheduleType,
  setClients,
  setMatterGrade,
  setMatterType,
  setTeams,
  setSessionId,
  setSessionType,
  setSessionStatus,
  setIsFiltring,
} = slice.actions;
