import PropTypes from "prop-types";
import { createContext, useEffect, useReducer, useCallback } from "react";
// utils
import axios from "axios";
//
import { isValidToken, setSession } from "./utils";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  isRegister: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isRegister: false,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      isAuthenticated: true,
      isRegister: true,
      first_name: action.payload.first_name,
      last_name: action.payload.last_name,
      is_active: action.payload.is_active,
      is_superuser: action.payload.is_superuser,
    };
  }
  if (action.type === "REGISTER") {
    return {
      ...state,
      isAuthenticated: false,
      isRegister: true,
      user: null,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const initialize = useCallback(async () => {
    try {
      const Token =
        typeof window !== "undefined" ? localStorage.getItem("Token") : "";

      if (Token && isValidToken(Token)) {
        setSession(Token);
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (data) => {
    const response = await axios.post(
      "https://back.dev.menwer.net/login/",
      data
    );
    if (response.status === 200) {
      setSession(response?.data?.token);

      localStorage.setItem(
        "user",
        JSON.stringify({
          id: response.data.id,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          is_active: response.data.is_active,
          is_superuser: response.data.is_superuser,
          employee_id: response.data.employee_id,
        })
      );
      if (response?.data?.is_active === true) {
        window.location.href = "/dashboard/overview";
      } else {
        window.location.href = "/auth/reset-password";
      }
    }

    dispatch({
      type: "LOGIN",
      payload: {
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        is_active: response.data.is_active,
        is_superuser: response.data.is_superuser,
      },
    });
    return response?.data?.token;
  };

  // LOGOUT
  const logout = async () => {
    localStorage.clear();
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
    navigate("/");
    /*  const response = await axios.post("https://back.dev.menwer.net/logout/");
    if (
      response.status === 200
    ) {
      localStorage.clear();
      setSession(null);
      dispatch({
        type: "LOGOUT",
      });
      window.location.href = "/";
    }*/
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
