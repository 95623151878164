import * as React from "react";

import { PieChart } from "@mui/x-charts/PieChart";

const colorPalette = ["#6a8490", "#babcdf", "#8aa5b0", "#c4c9ca"];

//___________________________________________________________________
export default function CasesSessionTypePieChart({ data = [] }) {
  const pieData = data
    ?.filter((job) => job?.count != null && job?.session_type__name != null)
    .map((job, idx) => ({
      id: idx,
      value: job.count ?? 0,
      label: job.session_type__name ?? "Unknown",
    }));
  return (
    <PieChart
      series={[
        {
          data: pieData,
        },
      ]}
      width={500}
      height={300}
      colors={colorPalette}
    />
  );
}
