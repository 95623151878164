import React, { useState } from "react";

import PropTypes from "prop-types";

import {
  TableRow,
  TableCell,
  Typography,
  Stack,
  IconButton,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Iconify from "../../../../../components/iconify";

import MenuPopover from "../../../../../components/menu-popover";
import { setOppenentId } from "../../../../../redux/slices/deptCollection";
import { getOpponentsDetailsService } from "../../../../../redux/services/debt_collection_service";

// ------------------------------------------------------------------------
const checkBoxSx = {
  color: "#6A8490",
  "&.Mui-checked": {
    color: "#6A8490",
  },
};

const innerCellBorderStyle = {
  borderWidth: "1px 0px",
  borderColor: "#eee",
  borderStyle: "solid",
};

const cellTextSx = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#2F2B2C",
};

const menuStyle = {
  width: "auto",
  boxShadow: " 2px 2px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  border: "1px solid rgba(103, 103, 103, 0.1)",
  p: "16px 22px 16px 22px",
};

const menuItemTextStyle = {
  ml: "10px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#2F2B2C",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: "#CCDDE4",
  },
}));

//_______________________________________________________
OpponentTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

//___________________________________________________________________
export default function OpponentTableRow(props) {
  const { row, selected, onSelectRow, selectedColumns } = props;
  const { fileStatus } = useSelector((state) => state?.deptCollection);
  const { clients } = useSelector((state) => state?.general);

  const file_status = fileStatus?.map((item) => ({
    value: item[0],
    label: item[1],
  }));
  // Function to find the label based on file_status value
  const getFileStatusLabel = (status) => {
    const match = file_status.find((item) => item.value === status);
    return match ? match.label : status;
  };

  const [openPopover, setOpenPopover] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setOpenPopover(event.currentTarget);
  };
  const renderCellContent = (column) => {
    switch (column.id) {
      case "-1":
        return <Typography sx={{ ...cellTextSx }}>{row?.id}</Typography>;
      case "0":
        return <Typography sx={{ ...cellTextSx }}>{row?.full_name}</Typography>;
      case "1":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {getFileStatusLabel(row?.file_status)}
          </Typography>
        );
      case "2":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.action_stage}</Typography>
        );
      case "3":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {row?.phone_numbers?.map((phone, index) => (
              <div>{phone?.phone_number}</div>
            ))}{" "}
          </Typography>
        );
      case "4":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.claim_value}</Typography>
        );
      case "5":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.amount_paid}</Typography>
        );
      case "6":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {
              clients?.find((c) => {
                return c.id === row?.assigned_employee;
              })?.first_name
            }
          </Typography>
        );
      case "7":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.entry_date}</Typography>
        ); //
      case "8":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.contract_number}</Typography>
        );
      case "9":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.execution_court}</Typography>
        );
      case "10":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {row?.reason_for_execution}
          </Typography>
        );
      case "11":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {row?.performance_fee_value}
          </Typography>
        );
      case "12":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {row?.performance_number_value}
          </Typography>
        );
      case "13":
        return <Typography sx={{ ...cellTextSx }}>{row?.expenses}</Typography>; //
      case "14":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.total_claim}</Typography>
        );
      case "15":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.total_remaining}</Typography>
        ); //
      case "16":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {row?.automatic_number}
          </Typography>
        );
      case "17":
        return <Typography sx={{ ...cellTextSx }}>{row?.address}</Typography>;
      case "18":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.governorate}</Typography>
        );
      case "19":
        return <Typography sx={{ ...cellTextSx }}>{row?.city}</Typography>;
      case "20":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.nationality}</Typography>
        );
      case "21":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.civil_number}</Typography>
        );
      case "22":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {row?.disconnected_number}
          </Typography>
        );
      case "23":
        return (
          <Typography sx={{ ...cellTextSx }}>{row?.court_date}</Typography>
        );
      default:
        return null;
    }
  };
  // -------- JSX Code -------
  return (
    <>
      <StyledTableRow hover selected={selected}>
        <TableCell padding="checkbox" sx={innerCellBorderStyle}>
          <Checkbox
            checked={selected?.includes(row.id)}
            onClick={(e) => {
              e.stopPropagation();
              onSelectRow(row?.id);
            }}
            sx={{ ...checkBoxSx }}
          />
        </TableCell>

        {selectedColumns.map((column) => (
          <TableCell key={column.id} align="center" sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              {renderCellContent(column)}
            </Stack>
          </TableCell>
        ))}

        {/** ACTIONS*/}
        <TableCell
          onClick={(e) => {
            e.stopPropagation();
          }}
          align="center"
          sx={innerCellBorderStyle}
        >
          <Typography component="div" sx={{ ...cellTextSx }}>
            <IconButton
              color={openPopover ? "inherit" : "default"}
              onClick={handleOpenPopover}
            >
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>
          </Typography>
        </TableCell>

        <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          arrow="top-right"
          sx={menuStyle}
        >
          {/*********************edit****************************************** */}
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/dashboard/collection/opponent/`);
              dispatch(setOppenentId(row?.id));
              dispatch(getOpponentsDetailsService(row?.id));
              handleClosePopover();
            }}
          >
            <img alt="" disabledEffect src="/assets/icons/edit.svg" />
            <Typography sx={{ ...menuItemTextStyle }}>التفاصيل</Typography>
          </MenuItem>

          {/**************************************************************** */}
        </MenuPopover>

        {/*** MENU */}

        {/***    <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          arrow="top-right"
          sx={menuStyle}
        >
          <MenuItem onClick={() => setOpenShowModal(true)}>
            <img alt="" disabledEffect src="/assets/icons/eye.svg" />
            <Typography sx={menuItemTextStyle}>عرض</Typography>
          </MenuItem>

          <MenuItem onClick={handlePrintInoice}>
            <img
              alt=""
              disabledEffect
              src="/assets/icons/document-download.svg"
            />
            <Typography sx={menuItemTextStyle}>تحميل</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenAddModal(true);
              handleClosePopover();
            }}
          >
            <img alt="" disabledEffect src="/assets/icons/wallet-add.svg" />
            <Typography sx={menuItemTextStyle}>
              {isLoadingCreate ? "جاري التحميل" : "تحصيل"}{" "}
            </Typography>
          </MenuItem>
          <MenuItem>
            <img alt="" disabledEffect src="/assets/icons/receipt-text.svg" />
            <Typography sx={menuItemTextStyle}>إيصال دفع </Typography>
          </MenuItem>
    
        </MenuPopover> */}
      </StyledTableRow>
    </>
  );
}
