import PropTypes from "prop-types";
// @mui
import { Switch, TablePagination, FormControlLabel } from "@mui/material";
// @mui
import {
  Box,
  Stack,
  Pagination,
  PaginationItem,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
// icons
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLocales } from "../../locales";
import { useState } from "react";

// ------ Style -----

const selectSx = {
  minWidth: "70px",
  "& .MuiOutlinedInput-root": {
    pr: "8px!important",
  },
  "& .MuiOutlinedInput-input": {
    pr: "20px!important",
    color: "#BCBCBC",
    fontWeight: "400",
    fontSize: "12.9807px",
    pt: "5px!important",
    pb: "5px!important",
  },
  "& .MuiSvgIcon-root": {
    color: "#BCBCBC",
    right: "0px !important",
  },

  "& fieldset": {
    borderWidth: "0px!important",
  },
};

const titleStyle = {
  color: "#BCBCBC",
  fontWeight: "400",
  fontSize: "12.9807px",
  lineHeight: "19px",
};

const mainHolderSx = {
  textAlign: "center",
  alignItems: "center",
  paddingBottom: "27px",
  paddingRight: "24px",
  paddingLeft: "24px",
  paddingTop: "24px",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: { xs: "column", sm: "row" },
};

TablePaginationCustom.propTypes = {
  dense: PropTypes.bool,
  onChangeDense: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  sx: PropTypes.object,
};

//_______________________________________________________
export default function TablePaginationCustom({
  count,
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  sx,
  rows,
  handlePaginate,
  handleChange,
  page,
  ...other
}) {
  const [numberOfRow, setNuberOfRow] = useState(5);

  const handleChanges = (e) => {
    // handleChange({ page: 1, items_per_page: e.target.value });
    setNuberOfRow(e.target.value);
  };
  return (
    <Box sx={{ ...mainHolderSx }}>
      {/* *** number of Rows Select *** */}
      <Box display="flex" alignItems="center">
        {/* <FormControl sx={{ ...selectSx }}>
          <Select
            IconComponent={(props) => <ArrowDropDownIcon {...props} />}
            value={numberOfRow}
            onChange={handleChanges}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {rowsPerPageOptions.map((number, index) => (
              <MenuItem sx={{ ...titleStyle }} value={number} key={index}>
                <em>{number} سطر بالصفحة </em>
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </Box>

      {/* ***  number of Pages *** */}
      <Stack textAlign={"left"}>
        <Pagination
          selected={true}
          page={page}
          onChange={handleChange}
          count={Math.ceil(count / 10)}
          defaultPage={page}
          color="primary"
          sx={{
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": {
                color: "#F5F5F5",
                backgroundColor: "#23264E",
                borderRadius: "0px",
              },
            },
          }}
          renderItem={(item) => (
            <PaginationItem
              active={1}
              value={1}
              slots={{
                previous: KeyboardDoubleArrowLeftIcon,
                next: KeyboardDoubleArrowRightIcon,
              }}
              {...item}
            />
          )}
        />
      </Stack>
    </Box>
  );
}
