import React, { useState } from "react";

import PropTypes from "prop-types";
// @ Mui
import {
  TableRow,
  TableCell,
  Typography,
  Stack,
  MenuItem,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import {
  setEmployeeId,
  setEmployeeName,
} from "../../../../redux/slices/general";

import { useDispatch } from "react-redux";

import Iconify from "../../../../components/iconify";

import MenuPopover from "../../../../components/menu-popover";
// ------------------------------------------------------------------------

const innerCellBorderStyle = {
  borderWidth: "1px 0px",
  borderColor: "#eee",
  borderStyle: "solid",
};

const cellTextSx = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#2F2B2C",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: "#CCDDE4",
  },
}));
const checkBoxSx = {
  color: "#6A8490",
  "&.Mui-checked": {
    color: "#6A8490",
  },
};

const menuStyle = {
  width: "auto",
  boxShadow: " 2px 2px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  border: "1px solid rgba(103, 103, 103, 0.1)",
  p: "16px 22px 16px 22px",
};

const menuItemTextStyle = {
  ml: "10px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#2F2B2C",
};
//_______________________________________________________
UsersTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

//___________________________________________________________________
export default function UsersTableRow(props) {
  const { row, selected, onSelectRow, selectedColumns } = props;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const renderCellContent = (column) => {
    switch (column.id) {
      case "0":
        return (
          <TableCell sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              <Typography sx={{ ...cellTextSx }}>
                {row.first_name} {row?.last_name}
              </Typography>
            </Stack>
          </TableCell>
        );
      case "1":
        return (
          <TableCell sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              <Typography sx={{ ...cellTextSx }}>{row?.group_name}</Typography>
            </Stack>
          </TableCell>
        );
      case "2":
        return (
          <TableCell align="center" sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              <Typography sx={{ ...cellTextSx }}>
                {row?.phone_numbers?.map((phone, index) => (
                  <div key={index}>{phone.phone_number}</div>
                ))}
              </Typography>
            </Stack>
          </TableCell>
        );

      case "3":
        return (
          <TableCell sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              <Typography sx={{ ...cellTextSx }}>{row?.email} </Typography>
            </Stack>
          </TableCell>
        );
      case "4":
        return (
          <TableCell sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              <Typography sx={{ ...cellTextSx }}>{row?.work_hours}</Typography>
            </Stack>
          </TableCell>
        );

      default:
        return null;
    }
  };

  // -------- JSX Code -------
  return (
    <>
      <StyledTableRow
        onClick={() => {
          // navigate("/dashboard/employee");
          dispatch(setEmployeeId(row?.id));
        }}
        hover
        selected={selected}
      >
        <TableCell padding="checkbox" sx={innerCellBorderStyle}></TableCell>

        {selectedColumns.map((column) => (
          <>{renderCellContent(column)}</>
        ))}

        {/** ACTIONS*/}
        <TableCell
          onClick={(e) => {
            e.stopPropagation();
          }}
          align="center"
          sx={innerCellBorderStyle}
        >
          <Typography component="div" sx={{ ...cellTextSx }}>
            <IconButton
              color={openPopover ? "inherit" : "default"}
              onClick={handleOpenPopover}
            >
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>
          </Typography>
        </TableCell>

        <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          arrow="top-right"
          sx={menuStyle}
        >
          {/*********************edit****************************************** */}
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              navigate("/dashboard/hr/employee/edit-employee");
              dispatch(setEmployeeId(row?.id));

              handleClosePopover();
            }}
          >
            <img alt="" disabledEffect src="/assets/icons/edit.svg" />
            <Typography
              sx={{ ...menuItemTextStyle }}
              onClick={() => {
                dispatch(setEmployeeId(row?.id));
                dispatch(setEmployeeName(row?.first_name));
              }}
            >
              تعديل
            </Typography>
          </MenuItem>

          {/**************************************************************** */}
        </MenuPopover>
      </StyledTableRow>
    </>
  );
}
