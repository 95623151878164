import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";

//______________________________________________________________________-
const StatisticCard = ({ title, number, IconComponent, type }) => {
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    let start = 0;
    const end = number;
    if (start === end) return;

    const totalDuration = 2000;
    const incrementTime = totalDuration / end;

    const timer = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === end) clearInterval(timer);
    }, incrementTime);

    return () => clearInterval(timer);
  }, [number]);
  const handleCardClick = (type) => {
    const routes = {
      EMPLOYEES: "/dashboard/users",
      CLIENTS: "/dashboard/clients",
      CASES: "/dashboard/lawsuit",
    };

    navigate(routes[type]);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      border={1}
      //   borderRadius={8}
      padding={2}
      width={200}
      height={200}
      backgroundColor="#ccdde4"
      color="#6A8490"
      sx={{
        backgroundImage: `linear-gradient(
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0.8)
        ), url("/assets/images/tabIcon.png")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        "&:hover": {
          backgroundColor: "#6a8490",
          cursor: "pointer",
          color: "#fff",
          backgroundImage: "none",
        },
      }}
      onClick={() => handleCardClick(type)}
    >
      <IconComponent fontSize="large" />
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h4">{count}</Typography>
    </Box>
  );
};

export default StatisticCard;
