import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";

import {
  useTable,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
  TableNoData,
  TableSkeleton,
} from "../../../../../components/table";

import { IconButton, Tooltip } from "@mui/material";

import Iconify from "../../../../../components/iconify";

import Scrollbar from "../../../../../components/scrollbar";

import OpponentTableRow from "./OpponentTableRow";

import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { getOpponentsForCollection } from "../../../../../redux/services/debt_collection_service";

// ----------------------------------------------------------------------

// --------- Style ------------

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: "auto",
  overflow: "unset",
  borderColor: theme.palette.primary.main,
  border: "1.99262px 1.99262px 9.9631px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  background: "#fff",
}));

const tableHolderSx = {
  minWidth: 800,
};

// ----------------------------------------------------------------------

export default function OpponentList({ page, setPage, setSelectedIds }) {
  const { opponent, isLoading, oppColumnsList, count, clientId } = useSelector(
    (state) => state?.deptCollection
  );
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);

  const selectedColumns = oppColumnsList.filter((item) => item.status);
  const TABLE_HEAD = [
    ...selectedColumns.map((item) => ({
      id: item.id,
      label: item.name,
      align: "center",
    })),
    { id: "", label: "", align: "center" },
  ];
  const {
    dense,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangeRowsPerPage,
  } = useTable();
  //

  let content;

  switch (true) {
    case isLoading:
      content = (
        <>
          {Array.from({ length: 4 }).map((_, index) => (
            <TableSkeleton key={index} />
          ))}
        </>
      );
      break;
    case tableData?.length > 0:
      content = tableData?.map((row) => (
        <OpponentTableRow
          key={row.id}
          row={row}
          onSelectRow={() => {
            onSelectRow(row.id);
          }}
          selected={selected}
          opponent={opponent}
          selectedColumns={selectedColumns}
        />
      ));
      break;
    default:
      content = <TableNoData isNotFound />;
      break;
  }
  useEffect(() => {
    setTableData(opponent);
  }, [dispatch, opponent]);

  useEffect(() => {
    if (selected?.length === 0) {
      setSelectedIds([]);
    } else if (selected?.length > 0) {
      setSelectedIds(selected?.map((row) => row?.id));
    }
  }, [selected]);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getOpponentsForCollection({ id: clientId, page: value }));
  };
  //____________________________________________________________

  return (
    <>
      <StyledTableContainer>
        <TableSelectedAction
          dense={dense}
          numSelected={selected?.length}
          rowCount={tableData?.length}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              tableData?.map((row) => row?.id)
            )
          }
          action={
            <Tooltip title="حذف">
              <IconButton color="#FFFFF">
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Tooltip>
          }
        />{" "}
        <Scrollbar>
          <Table size={dense ? "small" : "medium"} sx={{ ...tableHolderSx }}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row) => row?.id)
                )
              }
            />

            <TableBody>{content}</TableBody>
          </Table>
        </Scrollbar>{" "}
        <TablePaginationCustom
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChange={handleChange}
          onRowsPerPageChange={onChangeRowsPerPage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </StyledTableContainer>
    </>
  );
}
