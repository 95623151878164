import { createSlice } from "@reduxjs/toolkit";
import { createOfficeervice } from "../services/office_service";
import { invoicesColumnsList } from "../../utils/columnsLists";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingCreateOffice: false,
};

const slice = createSlice({
  name: "office",
  initialState,
  reducers: {

  },
  extraReducers: {
    // ==> CREATE office
    [createOfficeervice.fulfilled]: (state, action) => {
      state.isLoadingCreateOffice = false;
    },
    [createOfficeervice.rejected]: (state, action) => {
      state.isLoadingCreateOffice = false;
    },
    [createOfficeervice.pending]: (state) => {
      state.isLoadingCreateOffice = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {  } = slice.actions;
