import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// POST =>  CREATE MATTER
export const createMatterTypeService = createAsyncThunk(
  "matter/create_matter",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createMatterTypeURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// EDIT =>  EDIT MATTER
export const editMatterService = createAsyncThunk(
  "matter/editMatterService",
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.post(`${URLS.updateMatterURL}${id}/`, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET MATTER SERVICE
export const getMatterTypeService = createAsyncThunk(
  "matter/getMatterType",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMatterTypeURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET MATTER STATUSE
export const getMatterStatusService = createAsyncThunk(
  "matter/getMatterStatus",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMatterStatusURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE MATTER STATUSE
export const createMatterStatusService = createAsyncThunk(
  "matter/createMatterStatus",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createMatterStatusURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE MATTER SESSION
export const craeteSessionService = createAsyncThunk(
  "matter/craeteSession",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createCourtsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET SESSION
export const getSessionService = createAsyncThunk(
  "matter/getSession",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSessionURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE COURTS
export const createCourtsService = createAsyncThunk(
  "matter/createCourts",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createCourtsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET COURTS
export const getCourtsService = createAsyncThunk(
  "matter/getCourts",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getCourtsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE GOVERMENT
export const createGovernmentService = createAsyncThunk(
  "matter/createGovernment",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createGovernmentalURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET GOVERMENT
export const getGovernmentService = createAsyncThunk(
  "matter/getGovernment",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getGovernmentalURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE ATTRIBUTES
export const createAttributesService = createAsyncThunk(
  "matter/createAttributes",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createAttributesURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE SUB ATTRIBUTES
export const createSubAttributesService = createAsyncThunk(
  "matter/createSubAttributes",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createSubAttributesURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET SUB ATTRIBUTE
export const getSubAttributesService = createAsyncThunk(
  "matter/getSubAttributes",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSubAttributesURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE PROCEDURES
export const createProceduresService = createAsyncThunk(
  "matter/createProcedures",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createProceduresURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE SUB PROCEDURES
export const createSubProceduresService = createAsyncThunk(
  "matter/createSubProcedures",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createSubProceduresURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET SUB ATTRIBUTE
export const getSubProcWithSubAttService = createAsyncThunk(
  "matter/getSubProcWithSubAtt",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSubProceduresWithSubURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE EXPENSE
export const createExpenseService = createAsyncThunk(
  "matter/createExpense",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createExpenseURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET EXPENSE
export const getExpenseService = createAsyncThunk(
  "matter/getExpenseService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getExpenseURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE MATTER
export const createMatterService = createAsyncThunk(
  "matter/createMatter",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createMatterURL, data);
      return response;
    } catch (e) {
      console.log("e");
      console.log(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  GET MATTER
export const getMatterService = createAsyncThunk(
  "matter/getMatter",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMattersURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET INVOICES
export const getInvoiceService = createAsyncThunk(
  "matter/getInvoiceService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getInvoiceURL}${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET EXPENSES MATTER SERVICE
export const getExpensesMatterService = createAsyncThunk(
  "matter/getExpensesMatterService",
  async ({ id, page }, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getExpensesURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE INVOICE
export const createInvoiceService = createAsyncThunk(
  "matter/createInvoiceService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createInvoiceURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE MATTER expense
export const createExpenseMatterService = createAsyncThunk(
  "matter/createExpenseMatterService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createExpenseMatterURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET INVOICES FOR MATTER
export const getInvoicesForMatterService = createAsyncThunk(
  "matter/getInvoiceForMatterService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getInvoicesForMatterURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET  MATTER BY ID
export const getMatterByIdService = createAsyncThunk(
  "matter/getMatterByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getMatterURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST => CREATE CONTRACT
export const createContractService = createAsyncThunk(
  "matter/createContractService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createContractURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE INVOICE
export const createNewInvoiceService = createAsyncThunk(
  "matter/createNewInvoiceService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.addInvoiceURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE Filter
export const createFilterService = createAsyncThunk(
  "matter/createFilterService",
  async (data, thunkAPI) => {
    try {
      console.log("data filter is :", data);
      const response = await axios.post(
        `${URLS.filterMatterURL}?page=${data.page ? data.page : 1}`,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET CONTRACT
export const getContractsService = createAsyncThunk(
  "matter/getContractsService",
  async (matter_id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.getContractMatterURL}/${matter_id}`
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  ADD HOUR SERVICE Filter
export const addHoursService = createAsyncThunk(
  "matter/addHoursService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.addHoursURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  GET HOUR SERVICE Filter
export const getHoursService = createAsyncThunk(
  "matter/getHoursService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getHoursURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
